import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const contact = {
  title: 'Schedule Service Today',
  description: 'Send us a message and we will get started meeting your cooling and heating needs.',
  fields: {
    name: { label: 'Full name', autoComplete: 'given-name', id: 'name', required: true },
    email: { label: 'Email address', autoComplete: 'email', id: 'email', type: 'email' },
    phone: { label: 'Phone number', autoComplete: 'tel', id: 'phone', required: true },
    message: { label: 'What type of service do you need?', id: 'type-of-service', rows: 4, required: true },
    how: { label: 'How did you hear about us?', autoComplete: 'tel', id: 'how-did-you-hear-about-us' },
    budget: {
      label: 'Expected budget',
      name: 'budget',
      options: [
        { label: 'Less than $1,000', id: 'budget-under-1k', defaultValue: 'under-1k' },
        { label: '$1,000 – $5,000', id: 'budget-1k-5k', defaultValue: '1k-5k' },
        { label: '$5,000 – $10,000', id: 'budget-5k-10k', defaultValue: '5k-10k' },
        { label: '$10,000+', id: 'budget-over-10k', defaultValue: 'over-10k' },
      ],
    },
  },
  cta: 'Submit',
}

const Field = ({ label, type = 'text', autoComplete, id, required = false }) => (
  <div className="sm:col-span-2">
    <label htmlFor={id} className="block font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1">
      <input
        id={id}
        name={id}
        type={type}
        required={required}
        autoComplete={autoComplete}
        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary"
        aria-describedby={`${id}-description`}
      />
    </div>
  </div>
)

const Textarea = ({ label, type = 'text', autoComplete, id, required = false, rows }) => (
  <div className="sm:col-span-2">
    <label htmlFor={id} className="block font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1">
      <textarea
        id={id}
        name={id}
        type={type}
        required={required}
        autoComplete={autoComplete}
        rows={rows}
        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary"
        aria-describedby={`${id}-description`}
      />
    </div>
  </div>
)

const Radios = ({ label, name, options }) => (
  <fieldset className="sm:col-span-2">
    <legend className="block font-medium text-gray-700">{label}</legend>
    <div className="grid grid-cols-1 mt-4 gap-y-4">
      {options.map(item => (
        <div className="flex items-center">
          <input
            id={item.id}
            name={name}
            type="radio"
            defaultValue={item.defaultValue}
            className="w-4 h-4 border-gray-300 text-primary focus:ring-secondary"
          />
          <label htmlFor={item.id} className="ml-3">
            <span className="block text-gray-700">{item.label}</span>
          </label>
        </div>
      ))}
    </div>
  </fieldset>
)

const Contact = () => (
  <Layout>
    <Seo title="Get in touch to request service to meet all your HVAC needs" />

    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            className="object-cover w-full h-56 lg:absolute lg:h-full"
            src="../images/technician-with-logo.png"
            width={500}
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt="person installing ac unit"
            placeholder="none"
            imgStyle={{ objectPosition: 'top' }}
          />
        </div>
      </div>

      <div className="relative px-4 py-12 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-16 lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">{contact.title}</h2>
            <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">{contact.description}</p>
            <form
              action="https://formspree.io/f/mleavrrg"
              method="POST"
              className="grid grid-cols-1 mt-9 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <Field {...contact.fields.name} />
              <Field {...contact.fields.email} />
              <Field {...contact.fields.phone} />
              <Textarea {...contact.fields.message} />
              <Radios {...contact.fields.budget} />
              <Field {...contact.fields.how} />
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex justify-center px-4 py-2 text-xl font-medium text-white border border-transparent rounded-md shadow-sm lg:text-2xl bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                >
                  {contact.cta}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
